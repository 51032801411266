import React, { useContext, useState } from 'react';
import axios from 'axios';
import qs from 'qs'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from '../components/LeaveContext'
import { Context as UserContext } from '../components/UserContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import random from '../utils/random';
import PrivateRoute from '../components/PrivateRoute'

export default function New_Leave_Request_step5() {
  const {
    LeaveRequest, Member, Manager, Contact, stepFive, ErrorMessage,
  } = useContext(LeaveContext)
  const { forceChangePassword } = useContext(UserContext)

  const [state, setState] = useState({
    Error: {},
    Loading: false,
  })

  const onSubmit = (event) => {
    event.preventDefault()

    // check if all the checkbox's have been checked
    const inputs = event.target.getElementsByTagName('input');
    let isNotChecked = false;
    for (let x = 0; x < inputs.length; x++) {
      if (inputs[x].type === 'checkbox') {
        isNotChecked = inputs[x].checked;
        if (!isNotChecked) break;
      }
    }
    if (!isNotChecked) {
      setState(prev => ({ ...prev, Error: { NotChecked: 'You must acknowledge all of the above statements in order to submit your request for leave.' } }))
      return false
    }

    setState(prev => ({ ...prev, Loading: true }))

    // NEED to remove any information that doesnt need to be sent to data source,
    // like empty strings and data arrays
    const dataToSend = qs.stringify({
      Member: {
        FirstName: Member.FirstName,
        LastName: Member.LastName,
        DOB: Member.DOB,
        DOH: Member.DOH,
        EmployeeNumber: Member.EmployeeNumber,
        StreetAddress: Member.StreetAddress,
        CityAddress: Member.CityAddress,
        StateID: Member.StateID,
        ZipAddress: Member.ZipAddress,
      },
      Manager: {
        FullName: Manager.FullName,
        Title: Manager.Title,
        EmailAddress: Manager.EmailAddress,
        Phone: Manager.Phone,
        IsNotifiedManager: Manager.IsNotifiedManager === '' ? false : Manager.IsNotifiedManager === 'Yes',
      },
      Contact: {
        PreferedPhone: Contact.PreferedPhone,
        OtherPhone: Contact.OtherPhone,
        IsAcceptTextMessage: Contact.IsAcceptTextMessage === '' ? false : Contact.IsAcceptTextMessage === 'Yes',
        AcceptEmail: Contact.AcceptEmail,
        OtherEmail: Contact.OtherEmail,
        PreferCommunicationTypeID: Contact.PreferCommunicationTypeID,
      },
      LeaveRequest: {
        LastDayOfWork: LeaveRequest.LastDayOfWork,
        LeaveStart: LeaveRequest.LeaveStart,
        LeaveEnd: LeaveRequest.LeaveEnd,
        LeaveReasonID: LeaveRequest.LeaveReasonID,
        IsWorkRelatedHealthCondition: LeaveRequest.IsWorkRelatedHealthCondition === '' ? false : LeaveRequest.IsWorkRelatedHealthCondition === 'Yes',
        WorkRelatedExplanation: LeaveRequest.WorkRelatedExplanation,
        IsLeaveBecuaseOfFamily: LeaveRequest.IsLeaveBecuaseOfFamily !== '',
        FamilyRelationship: LeaveRequest.IsLeaveBecuaseOfFamily === '' ? '' : LeaveRequest.IsLeaveBecuaseOfFamily,
        IsPregnancyRelated: LeaveRequest.IsPregnancyRelated === '' ? false : LeaveRequest.IsPregnancyRelated === 'Yes',
        ExpectedDueDate: LeaveRequest.ExpectedDueDate === '' ? '' : LeaveRequest.ExpectedDueDate,
        LeaveExplanation: LeaveRequest.LeaveExplanation,
        IsPeriodic_Intermittent_ReducedScheduledLeave: LeaveRequest.IsPeriodic_Intermittent_ReducedScheduledLeave === '' ? false : LeaveRequest.IsPeriodic_Intermittent_ReducedScheduledLeave === 'Yes',
        Periodic_Intermittent_ReducedScheduledLeave_Explanation: LeaveRequest.Periodic_Intermittent_ReducedScheduledLeave_Explanation,
        IsPaidVacation: LeaveRequest.IsPaidVacation === '' ? false : LeaveRequest.IsPaidVacation === 'Yes',
        IsAcknowledgeAllStatements: true,
        IsFiledWorkerCompensationClaim: LeaveRequest.IsFiledWorkerCompensationClaim === '' ? false : LeaveRequest.IsFiledWorkerCompensationClaim === 'Yes',
        ChildDOB: LeaveRequest.ChildDOB === '' ? '' : LeaveRequest.ChildDOB,
      },
    });
    // Submit leave request and changed feilds
    const LeaveRequestURL = 'https://apps.cbcins.com/api/Service/LeaveRequest/Add'
    // Example of using a variable inside of the/ file to use inside of a react application
    // if(window.LeaveRequestURL)
    //   LeaveRequestURL = window.LeaveRequestURL

    axios
      .post(`${LeaveRequestURL}?V=${random()}`, dataToSend,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        })
      .then((res) => {
      // set the ID and URL
      // Step five will eliminate exsisting data so we cant send multple reuqest
        stepFive(res.data.LeaveRequestNumber, res.data.LeaveRequestURL)
        navigate('/New_Leave_Request_thankyou/');
        setState(prev => ({ ...prev, Loading: false }))
      })
      .catch((error) => {
      // running stepFive without ID or URL will eliminate the data and force refresh back to overview page
        if (error.response.data.Message) {
          const { Message } = error.response.data
          if (JSON.parse(Message).IsForcePasswordChange) {
            forceChangePassword(true)
            navigate('/Overview/');
            setState(prev => ({ ...prev, Loading: false }))
            return
          }
          ErrorMessage(error.response.data.Message)
          navigate('/New_Leave_Request_step4/');
          setState(prev => ({ ...prev, Loading: false }))
          return
        }
        ErrorMessage('Error submitting leave request')
        navigate('/New_Leave_Request_step4/');
        setState(prev => ({ ...prev, Loading: false }))
      });
  }

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Leave Request Step 5" />
        {state.Loading
          ? (
            <div className="content-outline-998" style={{ height: 300, textAlign: 'center' }}>
              <span style={{
                fontSize: 50, display: 'block', height: 300, lineHeight: '300px',
              }}
              >Submitting your request, Please wait...
              </span>
            </div>
          )
          : (
            <div className="content-outline-998">
              <ul className="steps">
                <li
                  className="past"
                  style={{ marginLeft: -2, paddingLeft: '1.75em', width: '7.75em' }}
                >
                  <span>
                    <strong>Step 1</strong>
                  PERSONAL INFO
                  </span>
                </li>
                <li className="past" style={{ width: '7.75em' }}>
                  <span>
                    <strong>Step 2</strong>MANAGER INFO
                  </span>
                  <i />
                </li>
                <li className="past" style={{ width: '7em' }}>
                  <span>
                    <strong>Step 3</strong>
                  CONTACT INFO
                  </span>
                  <i />
                </li>
                <li className="past" style={{ width: '8.5em' }}>
                  <span>
                    <strong>Step 4</strong> REQUEST DETAILS
                  </span>
                  <i />
                </li>
                <li className="present" style={{ width: '11.75em' }}>
                  <span>
                    <strong>Step 5</strong>
                  ACKNOWLEDGMENTS
                  </span>
                  <i />
                </li>
              </ul>
              <p className="clear">&nbsp;</p>
              <form
                style={{ position: 'relative', zIndex: 100 }}
                onSubmit={onSubmit.bind(this)}
              >
                <table width="100%" border="0" cellSpacing="1" cellPadding="0" className="leaveAknowledgements">
                  <tbody>
                    <tr>
                      <td colSpan="3" className="table-heading-color1">
                      Please acknowledge the following statements:&nbsp;
                      </td>
                    </tr>
                    {LeaveRequest && LeaveRequest.StatementsList.map(statement => (
                      <tr key={statement.Value}>
                        <td width="5%" align="center" className="table-graybg2">
                          <span className="Acknowledgement">
                            <input
                              id={`Statement_Value_${statement.Value}`}
                              type="checkbox"
                              name={`Statement_Value_${statement.Value}`}
                            />
                          </span>
                        </td>
                        <td colSpan="2" className="table-graybg1 alignleft">
                          <p>
                            {statement.Name}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>
                  <br />
                By clicking the &quot;Submit Leave Request&quot; button below,
                you attest that you have read, acknowledged and agree to all
                of the above terms and conditions.
                </p>
                <p className="clear">&nbsp;</p>
                <div className="errorMsgs">
                  {Object.keys(state.Error).map(x => <span key={state.Error[x]}>{state.Error[x]}<br /></span>)}
                </div>
                <p className="clear">&nbsp;</p>
                <p align="right">
                  <input
                    type="submit"
                    value="Submit Leave Request &raquo;"
                    style={{ fontSize: '1.35em' }}
                  />
                </p>
              </form>
            </div>
          )}
        <div className="whiteMask" />
      </Layout>
    </PrivateRoute>
  )
}
